import React from 'react';
import { graphql } from 'gatsby';
import { get } from 'lodash';

import HomePage from "./HomePage/HomePage.jsx";

class IndexPage extends React.Component {

  render() {
    const companies = get(this, 'props.data.allContentfulCompany.edges');
    return(
      <HomePage companies={companies} />
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
  	allContentfulCompany {
  	  edges {
  	    node {
  	      title
          description
          category
          url
          logo: logo {
            file {
              url
            }
          }
          ad: ad {
            fixed {
              ...GatsbyContentfulFixed
            }
            file {
              url
            }
          }
  	    }
  	  }
  	}
  }
`
